import lotteryApi from "apis/lotteryApi";
import classNames from "classnames";
import BetLotteryComponent from "components/BetLotteryComponent/BetLotteryComponent";
import BetLotteryComponentMoblie from "components/BetLotteryComponent/BetLotteryComponentMobile";
import BottomSheet from "components/BottomSheet/BottomSheet";
import CountDownComponent from "components/CountDownComponent/CountDownComponent";
import Modal from "components/Modal/Modal";
import ModalBetLottery from "components/ModalBetLottery/ModalBetLottery";
import TableHistoryBet from "components/TableHistoryBet/TableHistoryBet";
import ModalResultMobile from "components/TableResult/ModalResultMobile";
import TableResult from "components/TableResult/TableResult";
import TableTicketBet from "components/TableTicketBet/TableTicketBet";
import { setLoading } from "features/commonSlice";
import { toggleSidebar } from "features/layoutSlice";
import {
  addCurrentIssue,
  addGameId,
  addListMethod,
  addListTickets,
} from "features/lotterySlice";
import { scheduleLotteryI } from "interfaces/lottery.interface";
import MenuLottery from "partials/MenuLottery/MenuLottery";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "swiper/swiper-bundle.css";
import { icons } from "utils/icons";
import {
  convertNameLotteryToParam,
  formatStringWinnumberToResultData,
} from "utils/lotteryFormat";
import "./PlayBackPage.scss";
import { Helmet } from "react-helmet";
import { setConfigSeo } from "features/seoSlice";
import { listLogoPage } from "constant";
import { handleRedirectLink } from "utils/commonFormat";

function PlayBackPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idLottery } = useParams();

  const containerRef = useRef<HTMLDivElement>(null);
  const listGamesRef = useRef<HTMLDivElement>(null);

  const { listGames } = useSelector((state: any) => state.lottery);
  const { listMenuTrees } = useSelector((state: any) => state.common);
  const { isMobile, isTablet, isMiniDesktop } = useSelector(
    (state: any) => state.layout
  );
  const [listTabsGamesSelected, setListTabGamesSelected] = useState<any[]>([]);
  const [listMethodBet, setListMethodBet] = useState<any>([]);
  const [scheduleCurrentLottery, setScheduleCurrentLottery] =
    useState<scheduleLotteryI | null>(null);
  // const [schedulePreviousLottery, setSchedulePreviousLottery] =
  //   useState<scheduleLotteryI | null>(null);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [idLotterySelected, setIdLotterySelected] = useState<number>();

  const [statusBet, setStatusBet] = useState<boolean>(false);
  const [methodActivated, setMethodActivated] = useState<any>(null);
  const [nameGame, setNameGame] = useState<string>("");

  const [statusUpdateSchedule, setStatusUpdateSchedule] =
    useState<boolean>(false);
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [loadingIssue, setLoadingIssue] = useState<boolean>(false);
  const [statusCountDown, setStatusCountDown] = useState<boolean>(false);
  const [showModalBetType, setShowModalBetType] = useState<boolean>(false);
  const [showModalResultMobile, setShowModalResultMobile] =
    useState<boolean>(false);
  const [showMenuLottery, setShowMenuLottery] = useState<boolean>(false);
  const [dataResult, setDataResult] = useState<any>(null);
  const [listIssue, setListIssue] = useState<any>([]);
  const [hadTicket, setHadTicket] = useState<boolean>(false);
  const [offsetListGame, setOffsetListGame] = useState<number>(300);
  const { configSeo } = useSelector((state: any) => state?.seo);

  // useEffect(() => {
  //   if (isMobile) document.body.style.overflow = "hidden";

  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [isMobile]);

  useEffect(() => {
    dispatch(
      setConfigSeo({
        ...configSeo,
        title: `Mua số: ${nameGame} - Trợ lý xổ số hàng đầu Việt Nam`,
      })
    );
  }, [nameGame]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const newOffset = containerRef.current?.getBoundingClientRect().top;
        if (newOffset >= 84) {
          setOffsetListGame(newOffset);
        }
      }
    };
    window.document.addEventListener("scroll", handleScroll);
    return () => {
      window.document.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  useEffect(() => {
    if (idLottery && listGames) {
      const gameid = listGames
        ?.filter((item: any) => [0, 1, 2, 3].includes(item.type))
        .find((lottery: any) =>
          convertNameLotteryToParam(lottery.desc).includes(idLottery)
        )?.id;
      dispatch(addGameId(gameid));
      if (gameid) {
        setIdLotterySelected(gameid);
        dispatch(setLoading(true));
        handleGetModelsLottery(gameid);
        if (gameid !== 50) {
          handleGetScheduleCurrent(gameid);
          handleGetListIssue(gameid);
        } else {
          handleGetListFastLotteryIssue(gameid);
        }
        const gameNameFind = listGames.find(
          (games: any) => games.id === gameid
        );
        setNameGame(gameNameFind?.desc);
      }
    }
  }, [idLottery, listGames]);

  useEffect(() => {
    if (idLotterySelected && listMenuTrees) {
      const foundItem = getElementById(idLotterySelected, listMenuTrees);
      if (foundItem) {
        if (listTabsGamesSelected?.length > 0) {
          setListTabGamesSelected((prevGame: any) => {
            const exixts = listTabsGamesSelected?.some(
              (game: any) => Number(game.gameid) === idLotterySelected
            );
            if (!exixts) {
              const updatedPrevGames = prevGame.map((game: any) => ({
                ...game,
                isSelected: false,
              }));
              const itemTab = {
                ...foundItem,
                isSelected: Number(foundItem?.gameid) === idLotterySelected,
              };
              return [...updatedPrevGames, itemTab];
            } else {
              return prevGame.map((game: any) =>
                Number(game.gameid) === idLotterySelected
                  ? { ...game, isSelected: true }
                  : { ...game, isSelected: false }
              );
            }
          });
        } else {
          const newListTabGames = [
            {
              ...foundItem,
              isSelected: Number(foundItem?.gameid) === idLotterySelected,
            },
          ];
          setListTabGamesSelected(newListTabGames);
        }
      }
    }
  }, [idLotterySelected, listMenuTrees]);

  useEffect(() => {
    if (idLotterySelected && statusCountDown) {
      if (idLotterySelected !== 50) {
        handleGetScheduleCurrent(idLotterySelected);
      }
      setStatusUpdateSchedule(true);
      setTimeout(
        () => {
          if (idLotterySelected === 50) {
            handleGetListFastLotteryIssue(idLotterySelected);
          } else {
            handleGetListIssue(idLotterySelected);
          }
        },
        idLotterySelected === 50 ? 2000 : 1000
      );
      if (isMobile) setShowModalResultMobile(true);
    }
  }, [statusCountDown]);

  const handleGetModelsLottery = async (gameid: number) => {
    dispatch(setLoading(true));
    try {
      const { data } = await lotteryApi.getModelsLottery(
        [50, 100, 101, 102, 103, 104].includes(gameid)
          ? "100"
          : gameid.toString()
      );
      if (data.error_code === 0) {
        setListMethodBet(data.data);
        setMethodActivated(data.data[0]);
        dispatch(addListMethod(data.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetScheduleCurrent = async (gameid: number) => {
    try {
      const { data } = await lotteryApi.getDetailScheduleCurrent({
        gameid: gameid,
      });
      if (data.error_code === 0) {
        const resData = data.data;
        setScheduleCurrentLottery(resData);
        dispatch(addCurrentIssue(resData));
        setStartTime(new Date(resData.starttime).getTime());
        setEndTime(new Date(resData.endtime).getTime());
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      checkCountDown(false);
    }
  };

  // const handleGetSchedulePrevious = async (gameid: number) => {
  //   try {
  //     const { data } = await lotteryApi.getDetailSchedulesPrevious({
  //       gameid: gameid,
  //     });
  //     if (data.error_code === 0) {
  //       const resData = data.data;
  //       setSchedulePreviousLottery(resData);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     dispatch(setLoading(false));
  //     checkCountDown(false);
  //   }
  // };

  const handleGetListIssue = async (gameid: number, type?: string) => {
    if (type === "update") setLoadingIssue(true);
    try {
      const { data } = await lotteryApi.getListSchedules({
        gameid: gameid,
        numRecord: 24,
      });
      if (data) {
        const resList = data.data;
        setListIssue(resList);
        setDataResult(
          formatStringWinnumberToResultData(
            gameid,
            resList[0]?.winnumber
              ? resList[0]?.winnumber
              : resList[1]?.winnumber
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIssue(false);
      dispatch(setLoading(false));
      setStatusUpdateSchedule(false);
      setStatusCountDown(false);
      if (apiCalled && gameid !== 50) {
        if (showModalResultMobile) {
          setTimeout(() => {
            setShowModalResultMobile(false);
          }, 5000);
        }
      }
    }
  };

  const handleGetListFastLotteryIssue = async (gameid: number) => {
    try {
      const { data } = await lotteryApi.getListSchedulesFastLottery({
        gameid: gameid,
        numRecord: 10,
      });
      if (data) {
        const resList = data.data;
        setListIssue(resList);
        setDataResult(
          formatStringWinnumberToResultData(gameid, resList[0]?.winnumber)
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIssue(false);
      dispatch(setLoading(false));
      setStatusUpdateSchedule(false);
      setStatusCountDown(false);
      if (apiCalled && gameid !== 50) {
        if (showModalResultMobile) {
          setTimeout(() => {
            setShowModalResultMobile(false);
          }, 5000);
        }
      }
    }
  };

  const getElementById = (idLottery: number, arrFind: any) => {
    let foundElement;
    for (const menu of arrFind) {
      foundElement = menu.listMenu.find(
        (game: any) => Number(game.gameid) === idLottery
      );
      if (foundElement) {
        break;
      }
    }
    if (foundElement) {
      return foundElement;
    } else {
      return null;
    }
  };

  const checkCountDown = (check: boolean) => {
    if (check === true) {
      if (!apiCalled) {
        setApiCalled(true);
        setStatusCountDown(true);
        setStatusUpdateSchedule(true);
        checkCountDown(false);
      }
    } else {
      setApiCalled(false);
    }
  };

  const getStatusBet = (check: boolean) => {
    if (check) {
      setStatusBet(check);
      if (idLotterySelected === 50) {
        setStatusUpdateSchedule(true);
        setStatusCountDown(true);
        if (isMobile) {
          setShowModalResultMobile(true);
        }
      }
    }
  };

  const handleNavigateLottery = (data: any) => {
    const parts = data.url.split("/");
    const gameid = parts.slice(1).join("/");
    navigate(`/mua-so/${convertNameLotteryToParam(data?.name)}`);
    dispatch(addListTickets([]));
    dispatch(addGameId(gameid));
    dispatch(toggleSidebar(false));
  };

  const deleteTabGames = (gameid: string) => {
    const newListGames = listTabsGamesSelected.filter(
      (game: any) => Number(game.gameid) !== Number(gameid)
    );
    if (listTabsGamesSelected.length > 1) {
      if (Number(gameid) === idLotterySelected) {
        handleNavigateLottery(newListGames[newListGames?.length - 1]);
      }
      setListTabGamesSelected(newListGames);
    }
  };
  return (
    <>
      {!isMobile ? (
        <div className="playback-page w-full h-full flex flex-col items-center overflow-x-hidden">
          <div className="flex w-full h-full relative justify-center">
            <div
              className={`flex justify-center w-full ${
                isTablet ? "p-4" : "p-8"
              } max-w-[1440px]`}
            >
              <div
                className={classNames("h-full min-w-[256px] mr-4", {
                  hidden: isMiniDesktop || isTablet,
                })}
              >
                <div
                  className={`fixed delay-100 transition-all duration-500`}
                  style={{ top: offsetListGame }}
                  ref={listGamesRef}
                >
                  <MenuLottery className="max-h-[70vh] min-w-[256px]" />
                  <div className="flex flex-col min-w-[256px] max-w-[256px]">
                    {listLogoPage.map((logo: any, index: number) => (
                      <div
                        className={classNames(
                          "rounded-lg overflow-hidden mt-4 cursor-pointer"
                        )}
                        key={index}
                        onClick={() => handleRedirectLink(logo.url)}
                      >
                        <img
                          src={logo.img}
                          className="w-full h-full"
                          alt={logo.key}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="content-page w-full h-full overflow-y-auto flex justify-center"
                ref={containerRef}
              >
                <div className="main-center-wrap w-full flex flex-col">
                  <div className="flex items-center justify-between w-full mb-2">
                    {listTabsGamesSelected?.length > 0 && (
                      <div className="w-full flex flex-wrap">
                        {listTabsGamesSelected.map(
                          (game: any, index: number) => (
                            <div
                              className={`bg-white dark:bg-bgPrimary
                        border ${
                          game.isSelected
                            ? "border-primary"
                            : "border-transparent"
                        } 
                        rounded-[28px] p-2 pl-2.5 text-sm font-medium flex items-center 
                        ${
                          game.isSelected
                            ? "text-black dark:text-white"
                            : "text-text-secondary dark:text-white/40"
                        } hover:text-black dark:hover:text-white cursor-pointer 
                        mr-2 mb-2 `}
                              key={index}
                            >
                              <span onClick={() => handleNavigateLottery(game)}>
                                {game.name}
                              </span>
                              <img
                                src={require("assets/icons/cancel.png")}
                                className="w-5 h-5 ml-1 cursor-pointer"
                                alt={game.name}
                                onClick={() => deleteTabGames(game.gameid)}
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                    {idLotterySelected !== 50 && (
                      <div className="flex items-center">
                        <div className="flex items-center text-base font-semibold mr-2.5">
                          <span className=" text-text-secondary dark:text-white/40 text-nowrap mr-1">
                            Lượt xổ kế tiếp:
                          </span>
                          <span className="text-black dark:text-white text-nowrap">
                            {scheduleCurrentLottery?.issue}
                          </span>
                        </div>
                        <div className="count-down text-primary dark:text-white flex items-center text-sm bg-white dark:bg-black rounded-[10px] px-1.5 py-1">
                          <div className="p-1.5 rounded-[10px] mr-1.5 bg-primary/10">
                            <img
                              className="w-4 h-4"
                              src={icons.schedule}
                              alt={scheduleCurrentLottery?.issue}
                            />
                          </div>
                          <CountDownComponent
                            type="horizontal"
                            startTime={startTime}
                            endTime={endTime}
                            check={checkCountDown}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames("playback-wrap w-full flex", {
                      "justify-center": isMiniDesktop || isTablet,
                    })}
                  >
                    <div
                      className={classNames("w-full h-full mb-4", {
                        "max-w-[1024px]": isMiniDesktop || isTablet,
                      })}
                    >
                      <TableResult
                        gameid={idLotterySelected}
                        method={methodActivated}
                        currentIssue={scheduleCurrentLottery}
                        statusUpdate={statusUpdateSchedule}
                        clearStatusUpdate={() => setStatusUpdateSchedule(false)}
                        loading={loadingIssue}
                        startTime={startTime}
                        endTime={endTime}
                        listIssue={listIssue}
                        getListIssue={() =>
                          handleGetListIssue(
                            Number(idLotterySelected),
                            "update"
                          )
                        }
                        result={dataResult}
                        nameGame={nameGame}
                      />
                    </div>
                  </div>
                  <div className="bet-wrap w-full">
                    <BetLotteryComponent
                      listMethod={listMethodBet}
                      getCheck={getStatusBet}
                      gameid={idLotterySelected}
                      getMethodTypeActive={(method: any) =>
                        setMethodActivated(method)
                      }
                    />
                  </div>
                  <div className="bet-ticket w-full mt-7">
                    <TableTicketBet
                      getCheck={getStatusBet}
                      gameid={idLotterySelected}
                    />
                  </div>
                  <div className="table-history mt-7">
                    <TableHistoryBet
                      statusBet={statusBet}
                      gameid={idLotterySelected}
                      clearStatusBet={() => setStatusBet(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            "content-mobile w-full h-full flex flex-col items-center relative"
          )}
        >
          <div
            className={classNames(
              "wrap-mobile-playback w-full h-full max-w-[430px] flex flex-col overflow-auto no-scrollbar"
            )}
          >
            <div className="playback w-full">
              <div className="flex flex-col bg-surface-primary dark:bg-bgLayout px-4 py-2">
                <div className="flex flex-col">
                  <div
                    className="px-3 py-2.5 rounded-[10px] bg-white dark:bg-bgPrimary flex items-center border border-borderLightPrimary dark:border-borderPrimary"
                    onClick={() => setShowMenuLottery(true)}
                  >
                    <span className="text-black dark:text-white font-medium text-sm w-full mr-2">
                      {nameGame}
                    </span>
                    <img
                      src={icons.arrowDown}
                      className="w-5 h-5"
                      alt={nameGame}
                    />
                  </div>
                </div>
                {idLotterySelected !== 50 && (
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center mr-2">
                      <span className="text-sm text-black dark:text-white mr-2">
                        Lượt xổ:
                      </span>
                      <span className="issue-time text-primary text-sm">
                        {scheduleCurrentLottery?.issue}
                      </span>
                    </div>
                    <div className="count-down text-primary dark:text-white flex items-center text-sm bg-white dark:bg-black rounded-[10px] px-1.5 py-1">
                      <div className="p-1.5 rounded-[10px] mr-1.5 bg-primary/10">
                        <img
                          className="w-4 h-4"
                          src={icons.schedule}
                          alt={scheduleCurrentLottery?.issue}
                        />
                      </div>
                      <CountDownComponent
                        type="horizontal"
                        startTime={startTime}
                        endTime={endTime}
                        check={checkCountDown}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(
                "container-bet w-full h-full pb-[80px] flex flex-col bg-white dark:bg-bgPrimary transition-all duration-300 ease-in-out",
                {
                  "!pb-[194px]": hadTicket,
                }
              )}
              ref={containerRef}
            >
              <div className={`wrap-content-page w-full h-full flex`}>
                <BetLotteryComponentMoblie
                  listMethod={listMethodBet}
                  getCheck={getStatusBet}
                  gameid={idLotterySelected}
                  getMethodTypeActive={(method: any) =>
                    setMethodActivated(method)
                  }
                  getListIssue={() =>
                    handleGetListIssue(Number(idLotterySelected), "update")
                  }
                  listIssue={listIssue}
                  statusUpdate={statusUpdateSchedule}
                  methodActivated={methodActivated}
                  clearStatusUpdate={() => setStatusUpdateSchedule(false)}
                  getHadTicket={(status: boolean) => setHadTicket(status)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        isShow={showModalBetType}
        onClose={() => setShowModalBetType(false)}
      >
        <ModalBetLottery
          listMethod={listMethodBet}
          getType={(value: any) => setMethodActivated(value)}
          methodActivated={methodActivated}
          onClose={() => setShowModalBetType(false)}
        />
      </Modal>
      <Modal
        isShow={showModalResultMobile}
        onClose={() => setShowModalResultMobile(false)}
      >
        <ModalResultMobile
          gameid={idLotterySelected}
          method={methodActivated}
          dataResult={dataResult}
          issueCurrent={listIssue[0]?.issue}
          statusUpdate={statusUpdateSchedule}
          onClose={() => setShowModalResultMobile(false)}
        />
      </Modal>
      {isMobile && (
        <BottomSheet
          isShow={showMenuLottery}
          title="Danh sách đài"
          onClose={() => setShowMenuLottery(false)}
        >
          <MenuLottery
            className="h-full px-2 py-4"
            onClose={() => setShowMenuLottery(false)}
          />
        </BottomSheet>
      )}
    </>
  );
}

export default PlayBackPage;
